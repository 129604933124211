import React from 'react';
import Slick from 'react-slick';

class IntroContainer extends React.Component {
    componentWillMount = () => {
        document.body.className = 'page-intro';
    }

    render() {
        var settings = {
            fade: true,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return(
            <main role="main">

                <div className="bgs">
                    <Slick {...settings}>
                    <div className="cafe01"></div>
                    <div className="cafe02"></div>
                    <div className="cafe03"></div>
                    </Slick>
                </div>
                
                <div className="temporary">
                    <h1 className="cafe-name"><div className="inner">cafe modern B</div></h1>
                    <div className="cafe-info">
                        <address className="txt" data-abbr="a.">서울시 송파구 백제고분로34길 24 1층 <strong>모던비</strong></address>
                        <span className="txt" data-abbr="t.">070-8845-3424</span>
                    </div>
                </div>
                
            </main>
        );
    }
}

export default IntroContainer;
