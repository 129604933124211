import React, { Component } from "react";
import { Route, Switch, withRouter } from 'react-router-dom';

import "./assets/styles/modernb_ui.scss";

import {
  IntroPage,
} from './pages';



class App extends Component {
  state = {
  }


  render() {
    return (
      <div className="layout-wrap">
        <Switch>
          <Route exact path="/" component={IntroPage} />
          <Route component={IntroPage} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
