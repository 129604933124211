import 'core-js/es6/map';
import 'core-js/es6/set';

import React from "react";
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const Root = () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);


ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
